import axios from '../axios'

/* 
 * 产品费率管理模块
 */

// 分页查询
export const findPage = (params) => {
    return axios({
        url: '/pbs/pbs-price/list',
        method: 'get',
        params
    })
}

// 从crm同步产品费率
export const sync = (params) => {
    return axios({
        url: '/pbs/pbs-price/crmsync',
        method: 'get',
        params
    })
}