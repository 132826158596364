import axios from '../axios'

/* 
 * 客户管理模块
 */

// 分页查询
export const findPage = (params) => {
    return axios({
        url: '/pbs/pbs-crmticket/list',
        method: 'get',
        params
    })
}

// 从crm同步产品费率
export const sync = (params) => {
    return axios({
        url: '/pbs/pbs-crmticket/crmsync',
        method: 'get',
        params
    })
}

// 删除
export const remove = (data) => {
    return axios({
        url: `/pbs/pbs-crmticket/${data.id}`,
        method: 'delete',
        data
    })
}