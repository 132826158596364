import axios from '../axios'


/*  ************************************线索来源管理************************************ */

// 分页查询
export const resource_findPage = (params) => {
    params.page = params.pageNum
    return axios({
        url: '/cms/v1/clue/resouce',
        method: 'get',
        params
    })
}

// 查询所有线索
export const resource_all = (params) => {
    return axios({
        url: '/cms/v1/clue/resouce/all',
        method: 'get',
        params
    })
}

// 从crm同步线索来源
export const resource_sync = (data) => {
    return axios({
        url: '/cms/v1/clue/resouce',
        method: 'post',
        data
    })
}


// 修改线索来源
export const resource_edit = (id,data) => {
    return axios({
        url: `/cms/v1/clue/resouce/${id}`,
        method: 'patch',
        data
    })
}


// 编辑来源站点属性
export const resource_attr_edit = (data) => {
    return axios({
        url: `/cms/v1/clue/resouce/attr`,
        method: 'patch',
        data
    })
}

// 根据条件统计线索
export const resource_statistics = (params) => {
    return axios({
        url: '/cms/v1/clue/resouce/statistics',
        method: 'get',
        params
    })
}
/*  ************************************线索列表管理************************************ */

// 分页查询
export const clue_findPage = (params) => {
    params.page = params.pageNum
    return axios({
        url: '/cms/v1/clue/data',
        method: 'get',
        params
    })
}

// 添加线索
export const clue_add = (data) => {
    return axios({
        url: '/cms/v1/clue/data/add',
        method: 'post',
        data
    })
}


// 添加线索
export const clue_resend_manual = (data) => {
    return axios({
        url: '/cms/v1/clue/data/resend',
        method: 'post',
        data
    })
}

export const clue_verify = (params) => {
    return axios({
        url: '/api/v1/forget/password/register',
        method: 'get',
        params
    })
}

export const clue_captcha = (data) => {
    return axios({
        url: '/cms/v1/clue/data/send/captcha',
        method: 'post',
        data
    })
}


/*  ************************************表单管理************************************ */

// 根据产品ID查询表单
export const form_get = (id) => {
    return axios({
        url: `/cms/v1/clue/form/${id}`,
        method: 'get',
    })
}

// 添加或编辑
export const form_add = (data,id) => {
    return axios({
        url: `/cms/v1/clue/form/${id}`,
        method: 'post',
        data
    })
}

// 删除
export const form_remove = (id) => {
    return axios({
        url: `/cms/v1/clue/form/${id}`,
        method: 'delete'
    })
}

