import axios from '../axios'


/*  ************************************内容管理系统************************************ */


/*  ************************************文章************************************ */
const baseurl = '/cms/v1/cms/article'
// 分页查询
export const article_findPage = (params) => {
    params.page = params.pageNum
    return axios({
        url: `${baseurl}/list`,
        method: 'get',
        params
    })
}

// 根据文章ID查询
export const article_get = (id) => {
    return axios({
        url: `${baseurl}/${id}`,
        method: 'get',
    })
}

// 添加文章
export const article_add = (data,id) => {
    return axios({
        url: `${baseurl}`,
        method: 'post',
        data
    })
}

// 修改文章
export const article_edit = (id,data) => {
    return axios({
        url: `${baseurl}/${id}`,
        method: 'patch',
        data
    })
}

// 删除文章
export const article_remove = (id) => {
    return axios({
        url: `${baseurl}/${id}`,
        method: 'delete'
    })
}

export const article_detail = (params) => {
    return axios({
        url: `${baseurl}/detail`,
        method: 'get',
        params
    })
}

export const article_increment_pv = (id) => {
    return axios({
        url: `${baseurl}/incr/pv/${id}`,
        method: 'get'
    })
}

/*  ************************************表单************************************ */

const baseurl2 = '/cms/v1/cms/form'
// 分页查询
export const form_findPage = (params) => {
    params.page = params.pageNum
    return axios({
        url: `${baseurl2}/list`,
        method: 'get',
        params
    })
}

// 根据表单ID查询
export const form_get = (id) => {
    return axios({
        url: `${baseurl2}/${id}`,
        method: 'get',
    })
}

// 根据表单路径后缀查询
export const form_get_pathsuffix = (id) => {
    return axios({
        url: `${baseurl2}/suffix/${id}`,
        method: 'get',
    })
}

// 添加表单
export const form_add = (data,id) => {
    return axios({
        url: `${baseurl2}`,
        method: 'post',
        data
    })
}

// 修改表单
export const form_edit = (id,data) => {
    return axios({
        url: `${baseurl2}/${id}`,
        method: 'patch',
        data
    })
}

// 删除表单
export const form_remove = (id) => {
    return axios({
        url: `${baseurl2}/${id}`,
        method: 'delete'
    })
}

/*  ************************************表单数据************************************ */

// 查询表单分页数据
export const form_data_findPage = (params) => {
    params.page = params.pageNum
    return axios({
        url: `${baseurl2}/data`,
        method: 'get',
        params
    })
}


// 添加线索
export const form_data_add = (data) => {
    return axios({
        url: `${baseurl2}/data/add`,
        method: 'post',
        data
    })
}

// 添加线索
export const form_data_meeting_add = (data) => {
    return axios({
        url: `${baseurl2}/data/meeting/add`,
        method: 'post',
        data
    })
}

/*  ************************************ZOOM Account数据************************************ */

const baseurl3 = '/cms/v1/cms/zoom'
export const account_add = (data,id) => {
    return axios({
        url: `${baseurl3}`,
        method: 'post',
        data
    })
}
export const account_edit = (data) => {
    return axios({
        url: `${baseurl3}`,
        method: 'patch',
        data
    })
}
export const account_remove = (id) => {
    return axios({
        url: `${baseurl3}/${id}`,
        method: 'delete'
    })
}
export const account_findPage = (params) => {
    params.page = params.pageNum
    return axios({
        url: `${baseurl3}/list`,
        method: 'get',
        params
    })
}


/*  ************************************白名单/黑名单数据************************************ */

const baseurl4 = '/cms/v1/cms/whiteblacklist'
export const whiteblacklist_add = (data,id) => {
    return axios({
        url: `${baseurl4}`,
        method: 'post',
        data
    })
}
export const whiteblacklist_remove = (id) => {
    return axios({
        url: `${baseurl4}/${id}`,
        method: 'delete'
    })
}
export const whiteblacklist_findPage = (params) => {
    params.page = params.pageNum
    return axios({
        url: `${baseurl4}/list`,
        method: 'get',
        params
    })
}
