var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'layout-width': ['control', 'table', 'grid', 'divider', 'html'].includes(
      _vm.record.type
    )
  }},[(_vm.record && _vm.record.type === 'batch')?[_c('div',{class:[
        'batch-box',
        _vm.record.options.customClass ? _vm.record.options.customClass : '' ,
        _vm.record.key === _vm.selectItem.key ? 'active' : ''
      ],style:(_vm.record.options.customStyle),on:{"click":function($event){return _vm.handleSelectItem(_vm.record)}}},[_c('div',{staticClass:"batch-label"},[_vm._v(_vm._s(_vm.record.label))]),_c('draggable',_vm._b({staticClass:"draggable-box",attrs:{"tag":"div","force-fallback":true},on:{"start":function($event){return _vm.$emit('dragStart', $event, _vm.record.list)},"add":function($event){return _vm.$emit('handleColAdd', $event, _vm.record.list)}},model:{value:(_vm.record.list),callback:function ($$v) {_vm.$set(_vm.record, "list", $$v)},expression:"record.list"}},'draggable',{
          group: _vm.insertAllowed ? 'form-draggable' : '',
          ghostClass: 'moving',
          animation: 180,
          handle: '.drag-move'
        },false),[_c('transition-group',{staticClass:"list-main",attrs:{"tag":"div","name":"list"}},_vm._l((_vm.record.list),function(item){return _c('formNode',{key:item.key,staticClass:"drag-move",attrs:{"selectItem":_vm.selectItem,"record":item,"hideModel":_vm.hideModel,"config":_vm.config},on:{"update:selectItem":function($event){_vm.selectItem=$event},"update:select-item":function($event){_vm.selectItem=$event},"handleSelectItem":_vm.handleSelectItem,"handleColAdd":_vm.handleColAdd,"handleCopy":function($event){return _vm.$emit('handleCopy')},"handleShowRightMenu":_vm.handleShowRightMenu,"handleDetele":function($event){return _vm.$emit('handleDetele')}}})}),1)],1),_c('div',{staticClass:"copy",class:_vm.record.key === _vm.selectItem.key ? 'active' : 'unactivated',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleCopy')}}},[_c('i',{staticClass:"el-icon-copy-document"})]),_c('div',{staticClass:"delete",class:_vm.record.key === _vm.selectItem.key ? 'active' : 'unactivated',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleDetele')}}},[_c('i',{staticClass:"el-icon-delete"})])],1)]:(_vm.record &&  _vm.record.type === 'grid')?[_c('div',{staticClass:"grid-box",class:{ active: _vm.record.key === _vm.selectItem.key },on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectItem(_vm.record)}}},[_c('el-row',{staticClass:"grid-row",attrs:{"gutter":_vm.record.options.gutter}},_vm._l((_vm.record.columns),function(colItem,idnex){return _c('el-col',{key:idnex,staticClass:"grid-col",attrs:{"span":colItem.span || 0}},[_c('draggable',_vm._b({staticClass:"draggable-box",attrs:{"tag":"div","force-fallback":true},on:{"start":function($event){return _vm.$emit('dragStart', $event, colItem.list)},"add":function($event){return _vm.$emit('handleColAdd', $event, colItem.list)}},model:{value:(colItem.list),callback:function ($$v) {_vm.$set(colItem, "list", $$v)},expression:"colItem.list"}},'draggable',{
              group: 'form-draggable',
              ghostClass: 'moving',
              animation: 180,
              handle: '.drag-move'
            },false),[_c('transition-group',{staticClass:"list-main",attrs:{"tag":"div","name":"list"}},_vm._l((colItem.list),function(item){return _c('layoutItem',{key:item.key,staticClass:"drag-move",attrs:{"selectItem":_vm.selectItem,"startType":_vm.startType,"record":item,"hideModel":_vm.hideModel,"config":_vm.config},on:{"update:selectItem":function($event){_vm.selectItem=$event},"update:select-item":function($event){_vm.selectItem=$event},"handleSelectItem":_vm.handleSelectItem,"handleColAdd":_vm.handleColAdd,"handleCopy":function($event){return _vm.$emit('handleCopy')},"handleShowRightMenu":_vm.handleShowRightMenu,"handleDetele":function($event){return _vm.$emit('handleDetele')}}})}),1)],1)],1)}),1),_c('div',{staticClass:"copy",class:_vm.record.key === _vm.selectItem.key ? 'active' : 'unactivated',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleCopy')}}},[_c('i',{staticClass:"el-icon-copy-document"})]),_c('div',{staticClass:"delete",class:_vm.record.key === _vm.selectItem.key ? 'active' : 'unactivated',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleDetele')}}},[_c('i',{staticClass:"el-icon-delete"})])],1)]:(_vm.record && _vm.record.type === 'control')?[_c('div',{class:[
        'grid-box','control-form', 
        _vm.record.options.customClass ? _vm.record.options.customClass : '' ,
        _vm.record.key === _vm.selectItem.key ? 'active' : '',
        _vm.record.options && _vm.record.options.bordered ? 'form-table-bordered' : '' 
      ],style:(_vm.record.options.customStyle),on:{"click":function($event){return _vm.handleSelectItem(_vm.record)}}},[_c('draggable',_vm._b({staticClass:"draggable-box",attrs:{"tag":"div","force-fallback":true},on:{"start":function($event){return _vm.$emit('dragStart', $event, _vm.record.list)},"add":function($event){return _vm.$emit('handleColAdd', $event, _vm.record.list)}},model:{value:(_vm.record.list),callback:function ($$v) {_vm.$set(_vm.record, "list", $$v)},expression:"record.list"}},'draggable',{
          group: _vm.insertAllowed ? 'form-draggable' : '',
          ghostClass: 'moving',
          animation: 180,
          handle: '.drag-move'
        },false),[_c('transition-group',{staticClass:"list-main",attrs:{"tag":"div","name":"list"}},_vm._l((_vm.record.list),function(item){return _c('formNode',{key:item.key,staticClass:"drag-move",attrs:{"selectItem":_vm.selectItem,"record":item,"hideModel":_vm.hideModel,"config":_vm.config},on:{"update:selectItem":function($event){_vm.selectItem=$event},"update:select-item":function($event){_vm.selectItem=$event},"handleSelectItem":_vm.handleSelectItem,"handleColAdd":_vm.handleColAdd,"handleCopy":function($event){return _vm.$emit('handleCopy')},"handleShowRightMenu":_vm.handleShowRightMenu,"handleDetele":function($event){return _vm.$emit('handleDetele')}}})}),1)],1),_c('div',{staticClass:"copy",class:_vm.record.key === _vm.selectItem.key ? 'active' : 'unactivated',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleCopy')}}},[_c('i',{staticClass:"el-icon-copy-document"})]),_c('div',{staticClass:"delete",class:_vm.record.key === _vm.selectItem.key ? 'active' : 'unactivated',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleDetele')}}},[_c('i',{staticClass:"el-icon-delete"})])],1)]:(_vm.record && _vm.record.type === 'table')?[_c('div',{staticClass:"table-box",class:{ active: _vm.record.key === _vm.selectItem.key },on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectItem(_vm.record)}}},[_c('table',{class:[
          'table-layout','form-table',
          _vm.record.options.customClass ? _vm.record.options.customClass : '' ,
          _vm.record.options.bright ? 'bright' : '' ,
          _vm.record.options.small ? 'small' : '' ,
          _vm.record.options.bordered ? 'bordered' : '' 
        ],style:(_vm.record.options.customStyle)},_vm._l((_vm.record.trs),function(trItem,trIndex){return _c('tr',{key:trIndex},_vm._l((trItem.tds),function(tdItem,tdIndex){return _c('td',{key:tdIndex,class:['table-td', tdItem.class],style:(tdItem.style),attrs:{"colspan":tdItem.colspan,"rowspan":tdItem.rowspan},on:{"contextmenu":function($event){$event.preventDefault();return _vm.$emit('handleShowRightMenu', $event, _vm.record, trIndex, tdIndex , tdItem.colspan > 1 || tdItem.rowspan > 1)}}},[_c('draggable',_vm._b({staticClass:"draggable-box",attrs:{"tag":"div","force-fallback":true},on:{"start":function($event){return _vm.$emit('dragStart', $event, tdItem.list)},"add":function($event){return _vm.$emit('handleColAdd', $event, tdItem.list)}},model:{value:(tdItem.list),callback:function ($$v) {_vm.$set(tdItem, "list", $$v)},expression:"tdItem.list"}},'draggable',{
                group: 'form-draggable',
                ghostClass: 'moving',
                animation: 180,
                handle: '.drag-move'
              },false),[_c('transition-group',{staticClass:"list-main",attrs:{"tag":"div","name":"list"}},_vm._l((tdItem.list),function(item){return _c('layoutItem',{key:item.key,staticClass:"drag-move",attrs:{"selectItem":_vm.selectItem,"startType":_vm.startType,"record":item,"hideModel":_vm.hideModel,"config":_vm.config},on:{"update:selectItem":function($event){_vm.selectItem=$event},"update:select-item":function($event){_vm.selectItem=$event},"handleSelectItem":_vm.handleSelectItem,"handleColAdd":_vm.handleColAdd,"handleCopy":function($event){return _vm.$emit('handleCopy')},"handleShowRightMenu":_vm.handleShowRightMenu,"handleDetele":function($event){return _vm.$emit('handleDetele')}}})}),1)],1)],1)}),0)}),0),_c('div',{staticClass:"copy",class:_vm.record.key === _vm.selectItem.key ? 'active' : 'unactivated',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleCopy')}}},[_c('i',{staticClass:"el-icon-copy-document"})]),_c('div',{staticClass:"delete",class:_vm.record.key === _vm.selectItem.key ? 'active' : 'unactivated',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('handleDetele')}}},[_c('i',{staticClass:"el-icon-delete"})])])]:(_vm.record != undefined)?[_c('formNode',{key:_vm.record.key,attrs:{"selectItem":_vm.selectItem,"record":_vm.record,"config":_vm.config,"hideModel":_vm.hideModel},on:{"update:selectItem":function($event){_vm.selectItem=$event},"update:select-item":function($event){_vm.selectItem=$event},"handleSelectItem":_vm.handleSelectItem,"handleCopy":function($event){return _vm.$emit('handleCopy')},"handleDetele":function($event){return _vm.$emit('handleDetele')},"handleShowRightMenu":function($event){return _vm.$emit('handleShowRightMenu')}}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }