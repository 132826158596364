import axios from '../axios'
/*                                      文件上传                                       */
// 保存
export const upload = (data) => {
    return axios({
        url: '/pbs/excel/upload',
        method: 'post',
        headers:{
        'Content-type': 'multipart/form-data'
        },
        data
    })
}

// 查询文件列表
export const file_list = (params) => {
    return axios({
        url: '/pbs/excel/file/list',
        method: 'get',
        params
    })
}

// 删除某个文件
export const file_delete = (data) => {
    return axios({
        url: '/pbs/excel',
        method: 'delete',
        data
    })
}

// 分页查询
export const file_tempdata_list = (params) => {
    params.page = params.pageNum
    return axios({
        url: '/pbs/excel/temp/list',
        method: 'get',
        params
    })
}

// 将excel文件数据导入到临时表
export const file_tempdata_import = (params) => {
    return axios({
        url: '/pbs/excel/pentahu/execute',
        method: 'get',
        params
    })
}

// 将临时表数据导入到实体表
export const file_tempdata_confirm = (data) => {
    return axios({
        url: '/pbs/excel/temp/import',
        method: 'post',
        data
    })
}

/********************************************对账*********************************************** */

// 分页查询

// 汇总查询1
export const bill_statistical_month_supplier = (params) => {
    params.page = params.pageNum
    return axios({
        url: '/pbs/pbs-statistical-v2/supplier',
        method: 'get',
        params
    })
}

// 对账
export const bill_generate = (data) => {
    return axios({
        url: `/pbs/hw/v2/bill/${data.supplier}/generate/${data.month}`,
        method: 'post'
    })
}

// 汇总查询2
export const bill_statistical_search2 = (params) => {
    return axios({
        url: `/pbs/pbs-statistical/supplier/${params.s_id}/${params.bill_month}`,
        method: 'get',
        params
    })
}

// 汇总查询3
export const bill_statistical_search3 = (params) => {
    return axios({
        url: `/pbs/pbs-statistical/details`,
        method: 'get',
        params
    })
}