import Vue from 'vue'
import Router from 'vue-router'
import Cookies from "js-cookie"
Vue.use(Router)

import api from '@/http/api'
import store from '@/store'
import { getIFramePath, getIFrameUrl } from '@/utils/iframe'

Vue.use(Router)

const router = new Router({
  // base: "/inside/",
  mode: "history",
  routes: [
    {
      path: '/',
      name: '首页',
      // component: () => import('@/views/Home'),
      
      component: () => import('@/views/Cms/article/MeetingRegisterFrom'),
      // children: [
      //   { 
      //     path: '', 
      //     name: '主页', 
      //     component: () => import('@/views/Clue/Cluelist'),
      //     // redirect:'/dashBoard',
      //     meta: {
      //       icon: 'fa fa-home fa-lg',
      //       index: 0
      //     }
      //   }
      // ]
    },
    // {
    //   path: '/login',
    //   name: '登录',
    //   component: () => import('@/views/Login'),
    // },
    // {
    //   path: '/clue/new',
    //   name: '线索',
    //   component: () => import('@/views/Clue/New'),
    // },
    // {
    //   path: '/article/detail/:article_id',
    //   name: '文章详情',
    //   component: () => import('@/views/Cms/article/Detail'),
    //   props: true // 将路由参数作为组件的 props 传递
    // },
    {
      path: '/register/:form_id',
      name: '会议注册',
      component: () => import('@/views/Cms/article/MeetingRegisterFrom'),
      props: true // 将路由参数作为组件的 props 传递
    },
    {
      path: '/404',
      name: 'notFound',
      component: () => import('@/views/Error/404'),
    },
    {
      path: '/demo',
      name: 'demo',
      component: () => import('@/components/HelloWorld'),
    },
    
  ]
})


router.beforeEach((to, from, next) => {
    // 登录界面登录成功之后，会把用户信息保存在会话
    // 存在时间为会话生命周期，页面关闭即失效。
    let token = Cookies.get('token')
    let userName = sessionStorage.getItem('user')
    if (to.path === '/login') {
      // 如果是访问登录界面，如果用户会话信息存在，代表已登录过，跳转到主页
      if(token) {
        next({ path: '/' })
      } else {
        next()
      }
    } else {
      if( to.path==='/clue/new' || to.path.indexOf('/article/detail')>-1 || to.path.indexOf('/register/')>-1 || to.path=='/demo'){
        next()
      }else if (!token && to.meta.requireAuth) {
        // 如果访问非登录界面，且户会话信息不存在，代表未登录，则跳转到登录界面
        next({ path: '/login' })
      } else {
        // 加载动态菜单和路由
        addDynamicMenuAndRoutes(userName, to, from)
        next()
      }
    }
  })
  
  /**
  * 加载动态菜单和路由
  */
  function addDynamicMenuAndRoutes(userName, to, from) {
    // 处理IFrame嵌套页面
    handleIFrameUrl(to.path)
    if(store.state.app.menuRouteLoaded) {
      console.log('动态菜单和路由已经存在.')
      return
    }else{
      let iFrameUrl = {'path':to.path, 'url':to.path}
      store.commit('addIFrameUrl', iFrameUrl)
    }
    api.menu.findNavTree({'system':'huawan-a-cms'})
    .then(res => {
      // 添加动态路由
      let dm = []
      res.data[0].children.forEach(e => {
        if(e.id===703 || e.id===704 || e.id==705){
          dm.push(e)
          return
        }
      });
      
      let dynamicRoutes = addDynamicRoutes(dm)
      // 处理静态组件绑定路由
      handleStaticComponent(router, dynamicRoutes)
      router.addRoutes(router.options.routes)
      // 保存加载状态
      store.commit('menuRouteLoaded', true)
      // 保存菜单树
      store.commit('setNavTree', dm)
    }).then(res => {
      api.user.findPermissions({'name':userName}).then(res => {
        // 保存用户权限标识集合
        store.commit('setPerms', res.data)
      })
    })
    .catch(function(res) {
    })
  }
  
  /**
   * 处理路由到本地直接指定页面组件的情况
   * 比如'代码生成'是要求直接绑定到'Generator'页面组件
   */
  function handleStaticComponent(router, dynamicRoutes) {
    // for(let j=0;j<dynamicRoutes.length; j++) {
    //   if(dynamicRoutes[j].name == '代码生成') {
    //     dynamicRoutes[j].component = Generator
    //     break
    //   }
    // }
    router.options.routes[0].children = router.options.routes[0].children.concat(dynamicRoutes)
  }
  
  /**
   * 处理IFrame嵌套页面
   */
  function handleIFrameUrl(path) {
    // 嵌套页面，保存iframeUrl到store，供IFrame组件读取展示
    let url = path
    let length = store.state.iframe.iframeUrls.length
    for(let i=0; i<length; i++) {
      let iframe = store.state.iframe.iframeUrls[i]
      if(path != null && path.endsWith(iframe.path)) {
        url = iframe.url
        store.commit('setIFrameUrl', url)
        break
      }
    }
  }
  
  /**
  * 添加动态(菜单)路由
  * @param {*} menuList 菜单列表
  * @param {*} routes 递归创建的动态(菜单)路由
  */
  function addDynamicRoutes (menuList = [], routes = []) {
   var temp = []
   for (var i = 0; i < menuList.length; i++) {
     if (menuList[i].children && menuList[i].children.length >= 1) {
       temp = temp.concat(menuList[i].children)
     } else if (menuList[i].url && /\S/.test(menuList[i].url)) {
        menuList[i].url = menuList[i].url.replace(/^\//, '')
        // 创建路由配置
        var route = {
          path: menuList[i].url,
          component: null,
          name: menuList[i].name,
          meta: {
            icon: menuList[i].icon,
            index: menuList[i].id,
            requireAuth: menuList[i].requireAuth?true:false,
          }
        }
        let path = getIFramePath(menuList[i].url)
        if (path) {
          // 如果是嵌套页面, 通过iframe展示
          route['path'] = path
          route['component'] = resolve => require([`@/views/IFrame/IFrame`], resolve)
          // 存储嵌套页面路由路径和访问URL
          let url = getIFrameUrl(menuList[i].url)
          let iFrameUrl = {'path':path, 'url':url}
          store.commit('addIFrameUrl', iFrameUrl)
        } else {
         try {
           // 根据菜单URL动态加载vue组件，这里要求vue组件须按照url路径存储
           // 如url="sys/user"，则组件路径应是"@/views/sys/user.vue",否则组件加载不到
           let array = menuList[i].url.split('/')
           let url = ''
           for(let i=0; i<array.length; i++) {
              url += array[i].substring(0,1).toUpperCase() + array[i].substring(1) + '/'
           }
           url = url.substring(0, url.length - 1)
           route['component'] = resolve => require([`@/views/${url}`], resolve)
         } catch (e) {}
       }
       routes.push(route)
     }
   }
   if (temp.length >= 1) {
     addDynamicRoutes(temp, routes)
   } else {
     console.log('动态路由加载...')
     console.log(routes)
     console.log('动态路由加载完成.')
   }
   return routes
  }
  
  export default router
  