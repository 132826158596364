import axios from '../axios'

/* 
 * 客户管理模块
 */

// 分页查询
export const findPage = (params) => {
    return axios({
        url: '/pbs/pbs-customer/list',
        method: 'get',
        params
    })
}

// 从crm同步产品费率
export const sync = (params) => {
    return axios({
        url: '/pbs/pbs-customer/crmsync',
        method: 'get',
        params
    })
}

// 编辑
export const edit = (data) => {
    return axios({
        url: `/pbs/pbs-customer/${data.id}`,
        method: 'patch',
        data
    })
}

// 删除
export const remove = (data) => {
    return axios({
        url: `/pbs/pbs-customer/${data.id}`,
        method: 'delete',
        data
    })
}


/* 
 * 客户充值模块
 */

// 充值
export const recharge_save = (data) => {
    return axios({
        url: `/pbs/pbs-recharge/save`,
        method: 'post',
        data
    })
}

// 分页查询充值记录
export const recharge_findPage = (params) => {
    return axios({
        url: '/pbs/pbs-recharge/list',
        method: 'get',
        params
    })
}
// 删除充值记录
export const recharge_remove = (data) => {
    return axios({
        url: `/pbs/pbs-recharge/${data.id}`,
        method: 'delete',
        data
    })
}

// 分页查询账号信息
export const account_findPage = (params) => {
    return axios({
        url: '/pbs/pbs-account/list',
        method: 'get',
        params
    })
}

// 分页查询联系人信息
export const contact_findPage = (params) => {
    return axios({
        url: '/pbs/pbs-contact/list',
        method: 'get',
        params
    })
}

// 修改联系人信息
export const contact_modify = (data) => {
    return axios({
        url: `/pbs/pbs-contact/modify`,
        method: 'put',
        data
    })
}

// 分页查询优惠价格信息
export const discount_findPage = (params) => {
    return axios({
        url: '/pbs/pbs-discount/list',
        method: 'get',
        params
    })
}