import axios from '../axios'

/* 
 * 供应商管理模块
 */

// 分页查询
export const findPage = (params) => {
    return axios({
        url: '/pbs/pbs-supplier/list',
        method: 'get',
        params
    })
}

// 保存
export const save = (data) => {
    return axios({
        url: '/pbs/pbs-supplier/save',
        method: 'post',
        data
    })
}

// 删除
export const remove = (data) => {
    return axios({
        url: `/pbs/pbs-supplier/${data.id}`,
        method: 'delete',
        data
    })
}

// 编辑
export const edit = (data) => {
    return axios({
        url: `/pbs/pbs-supplier/${data.id}`,
        method: 'patch',
        data
    })
}