/* 
 * 接口统一集成模块
 */
import * as login from './moudules/login'

import * as supplier from './moudules/supplier'
import * as rate from './moudules/rate'
import * as customer from './moudules/customer'
import * as crmticket from './moudules/crmticket'
import * as billing from './moudules/billing'

import * as clue from './moudules/clue'
import * as cms from './moudules/cms'

import * as user from './moudules/user'
import * as dept from './moudules/dept'
import * as role from './moudules/role'
import * as menu from './moudules/menu'
import * as dict from './moudules/dict'
import * as log from './moudules/log'


// 默认全部导出
export default {
    login,
    supplier,
    rate,
    customer,
    crmticket,
    billing,

    clue,
    cms,
    
    user,
    dept,
    role,
    menu,
    dict,
    log
}