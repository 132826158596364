var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.record.type === 'text')?_c('div',{style:({ textAlign: _vm.record.options.textAlign })},[_c('span',[_vm._v(_vm._s(_vm.record.label))])]):(_vm.record.type === 'html')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.record.options.defaultValue)}}):(
              !(_vm.record.options.hidden === true) &&
                ( [
                  'input',
                  'textarea',
                  'date',
                  'time',
                  'datePicker',
                  'number',
                  'radio',
                  'checkbox',
                  'select',
                  'rate',
                  'switch',
                  'slider', 
                  'cascader'
                ].includes(_vm.record.type) || _vm.customList.includes(_vm.record.type) )  && !(_vm.record.type == 'select' && _vm.renderPreview &&  _vm.record.options.previewHidden )
            )?_c('el-form-item',{staticClass:"table-item",attrs:{"rules":_vm.recordRules,"prop":_vm.parentModel + '.' + _vm.index + '.' + _vm.record.model,"label-width":"0px"}},[_c('BaseItem',{attrs:{"models":_vm.domains,"record":_vm.record,"renderPreview":_vm.renderPreview}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }