<template>
	<div>  
		<el-checkbox v-model="record.options.model" id="privateAndTerms">
            <span :disabled="disabled" v-html="record.options.defaultValue" :style="record.options.style ? record.options.style : null"></span>
		</el-checkbox>
		 
		<!-- 多选框 --> 
		<!-- <el-checkbox-group    v-model="record.model" :disabled="disabled" >
			<el-checkbox :label="record.model" > 
				<span :disabled="disabled" v-html="record.options.defaultValue" :style="record.options.style ? record.options.style : null"></span>
			</el-checkbox>
		</el-checkbox-group> -->
	</div> 
</template>
<script>
  export default { 
    props: {    
	    record: {//组件数据
	      type: Object,
	      required: true
	    }, 
	    models: {// 表单数组 
	      type: Object,
	      required: true
	    }, 
	    disabled: { // 是否禁用
	      type: Boolean,
	      default: false
	    } , 
		checkValues: { // 是否选中
			type: Boolean,
	      	default: false
		},
    },
    methods: { 
		handleChange(value, key , type) {
			this.$set(this.models, this.record.model, value)
			// change事件  
			this.$emit("change", value, key); 

			
			let labels = []
			// 获取数据 判断从ajax 还是本地默认配置
			let datas = this.record.options.dynamic > 0 ? this.checkValues : this.record.options.options
			if(!datas) {
				datas = []
			}

			let as = []

			if(!(value instanceof Array)){
				as = [value]
			} else {
				as = value
			}

			for(let i = 0 ; i < as.length ; i++){
				const v = as[i] 
				const fs = datas.filter(t=>t[this.itemProp.value] == v)
				if(fs && fs.length > 0) {
					const label = fs[0][this.itemProp.label]

					labels.push(label)
				}
				
			}

			const modelLabel = this.record.model + '_label'
			//this.models[modelLabel] = labels.join(',')
			this.$set(this.models , modelLabel , labels.join(','))


			// 找到当前选择的数据实体  
			// 获取数据
			const cvalues = (this.record.options.dynamic == 1 && this.record.options.remoteFunc  ?  this.checkValues : this.record.options.options)
	
			const fs = cvalues.filter(t=>t[this.itemProp.value] == value)
	
			if(fs && fs.length > 0) {
				const select = fs[0] 

				// 构建函数 去执行 
				this.$nextTick(()=>{
				const scriptFunc = this.record.options.selectCb
				const func =  '{' + scriptFunc + '}'  
				const Fn = new Function('$' , '$select', func)
				
				Fn(this.models,select)

				
				})
			
			} 
		},
    }
  }
</script>
<style >
  .custom_checkbox .el-form-item__error{
    text-align: center !important;
    width: 100% !important;
  }
</style>