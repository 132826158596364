import Vue from 'vue'
import App from './App.vue'
// import './assets/plugins/element.js'
// import api from './mock'
// import "@/mock/index.js"
import axios from 'axios'
import router from './router'
import api from './http' 
import i18n from './i18n'
import store from './store'
import global from '@/utils/global'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.min.css'
import '@/assets/iconfont/iconfont.css'
import ElementUI from 'element-ui'
import VueClipboard from 'vue-clipboard2'


Vue.prototype.axios = axios //挂载到原型，可以在全局使用
Vue.config.productionTip = false
Vue.prototype.global = global

Vue.use(ElementUI)
Vue.use(api)
// Vue.use(mock)
Vue.use(VueClipboard)


 // 导入组件库
 import FormDesign from './views/Customizeform/index'
 import './views/Customizeform/form/src/form-design.css';
 // 注册组件库
 Vue.use(FormDesign)

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
